<template>
    <main>
        <HeaderTab :title="$t('global.language')" />
        <div id="content">
            <div class="container-fluid">
                <div class="box">
                    <label>
                        {{ $t('compte.lang.choice') }}
                    </label>
                    <e-select
                        v-if="currentLang"
                        v-model="currentLang"
                        :options="languages"
                        :allow-empty="false"
                        label="label"
                        track-by="lang"
                    >
                        <template slot="singleLabel">
                            <img class="mt-1" :src="require(`GroomyRoot/assets/img/flag-iso/${currentLang.lang.toUpperCase()}.png`)">
                            <span class="ml-2">{{ currentLang.label }}</span>
                        </template>
                        <template slot="option" slot-scope="props">
                            <img :src="require(`GroomyRoot/assets/img/flag-iso/${props.option.lang.toUpperCase()}.png`)">
                            <span class="ml-2">{{ props.option.label }}</span>
			            </template>
                    </e-select>

                    <div class="mt-4">
                        <b-button
                            variant="primary"
                            :disabled="processingLang"
                            @click="submitLanguage"
                        >
                            <font-awesome-icon v-if="processingLang" :icon="['fas', 'spinner']" pulse />
                            {{ $t("compte.lang.edit") }}
                        </b-button>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import Vue from 'vue'
import User from '@/mixins/User'

export default {
    name: 'SwitchLang',
    mixins: [User],
    data: () => ({
        languages: [],
        currentLang: Vue.i18n.locale(),
		processingLang: false
    }),
    created() {
        this.init_component()
    },
    methods: {
        init_component() {
            this.languages = []
            Vue.i18n.locales().forEach(lang => {
                const option = {
                    label: this.$t(`compte.lang.locales.${lang}`),
                    lang
                }
                this.languages.push(option)

                if (Vue.i18n.locale() === lang) {
                    this.currentLang = option
                }
			})
        },
        async submitLanguage() {
            try {
                this.processingLang = true
                await this.setLanguage(this.currentLang.lang)
                await this.loadConfig()
                this.initSession()
            } catch (err) {
                console.error(err)
                this.failureToast()
            } finally {
                this.processingLang = false
            }
		}
    },
    components: {
        HeaderTab: () => import('GroomyRoot/components/HeaderTab'),
    }
}
</script>